import { navigate } from "gatsby";
import { useEffect } from "react";
import { isLoggedIn } from "../services/auth";

const Index = () => {
  useEffect(() => {
    if (isLoggedIn()) {
      navigate("/app/main");
    } else {
      navigate("/app/login");
    }
  }, []);
  return null;
};

export default Index;
