export const isBrowser = () => typeof window !== "undefined"
export const getUser = () =>
  isBrowser() && window.localStorage.getItem("adidasUser")
    ? JSON.parse(window.localStorage.getItem("adidasUser"))
    : {}

const setUser = user =>
  window.localStorage.setItem("adidasUser", JSON.stringify(user))

export const handleLogin = ({ email, region }) => {
  return setUser({
    region,
    email,
  })
}

export const isLoggedIn = () => {
  const user = getUser()
  if (user.email && user.email.success) {
    isBrowser() && window.localStorage.removeItem("adidasUser")
    return false
  } else {
    return !!user.email
  }
}
export const logout = callback => {
  setUser({})
  callback()
}
